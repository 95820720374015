import React, {useEffect} from 'react'
import "../styles/showcase.css"

const Showcase = () => {

  useEffect(() => {
    document.title = "Contestations.AI | Showcase";
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  },[])

  return (
    <div id="showcase-page">
      <h1>Showcase</h1>
      {/* <div id="showcase-container"> */}
        <div id="performance-container">
          <h2>Extended Performance Arts Program</h2>
            <div>
              <div >
              <p><a href="https://pulusound.fi" target="_blank" rel="noreferrer" className="link">Miranda Kastemaa</a> will perform improvised livecoding music set on supercollider -programming language. This means all the sounds and music are being created live on the spot. Miranda is a Helsinki-based musician, programmer and sound designer exploring sonic universes through a variety of algorithmic, electronic and acoustic means.</p>
              </div>
              <div >
              <p><a href="https://www.saxposition.com" target="_blank" rel="noreferrer" className="link">Saxposition:</a> Embark on a journey of sound with this experimental duo of Jenni Peltoniemi and Ville MJ Hyvönen, where the fusion of saxophone and cutting-edge technology creates an unforgettable live performance. The combination of live sampling, looping, synths and field recordings blend together seamlessly, culminating in a unique and captivating musical experience.</p>
              </div>
              <div >
              <p>Architect <a href="https://fi.wikipedia.org/wiki/Tuomas_Toivonen" target="_blank" rel="noreferrer" className="link">Tuomas Toivonen</a> will perform an experimental DJ set. Tuomas runs Kulttuurisauna, a public bath and cultural oasis in Merihaka. He is known as a versatile musician, producer and performer, and is the co-founder of the band <a href="https://found.ee/giantrobot_withinagainst" target="_blank" rel="noreferrer" className="link">Giant Robot</a>.</p>
              </div>
            </div>
        </div>
        <div id="img-container" className="frame-box" style={{"--w": "40px", "--b": "6px"}}>
          <img src="img/showcase/saxposition.jpg" className="img-overlay" alt=""/>
          <img src="img/showcase/saxposition2.jpg" className="img-overlay" alt=""/>
          </div>
        <div id="mediaart-container">
          <h2>Media/Arts Showcase</h2>
          <p>
          In addition to talks and panels, the event will showcase experimental digital media works by artists critically engaging contestations in AI technologies and society, curated by <a href="https://www.aalto.fi/fi/ihmiset/matti-niinimaki" target="_blank" rel="noreferrer" className="link">Matti Niinimäki</a>, Department of Art and Media, Aalto University.
          </p>
        </div>
      {/* </div> */}
    </div>
  )
}

export default Showcase