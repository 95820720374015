import React from 'react'
import { useNavigate } from "react-router-dom"
import { motion } from "framer-motion"

const Arrowbutton = ({text, color, link}) => {
    const navigate = useNavigate();

    const textVariant = {
      hover: {
        
      }
    }
  return (
    <motion.div whileHover="hover" className="arrow-button" onClick={() => {navigate(`${link}`)}}>
        <motion.div variants={textVariant} style={{"color": `${color}`}}>{text}</motion.div>
    </motion.div>
  )
}

export default Arrowbutton