import { useState, useEffect } from "react"
import { apiKey } from "./key.js"

const randomInt = (min, max) => {
    return Math.floor((Math.random() * (max-min + 1)) + min)
  }

const menuPos = () => {
  const width = window.innerWidth;
  const height = window.innerHeight;

  let size = randomInt(Math.max(width, height)*0.10,Math.max(width, height)*0.20);
  let posx = randomInt(25, 75-size/20)
  let posy = randomInt(8, 92-size/10)
  return [posx+"vw", posy+"vh", size]
}

const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }

    const listener = () => {
      setMatches(media.matches);
    };

    if (typeof media.addEventListener === "function") {
      media.addEventListener("change", listener);
    } else {
      media.addListener(listener);
    }

    return () => {
      if (typeof media.removeEventListener === "function") {
        media.removeEventListener("change", listener);
      } else {
        // media.removeListener(listenerList);
      }
    };
  }, [matches, query]);

  return matches;
}

const useIsSmall = () => useMediaQuery("(min-width: 1000px");

const sheetId = "1pnOiWK83kboNIWVq__f7ptGbpwqrffFUrI5n7gxMLnY"
const sheetName = "people"
const sheetTeam = "team"
const sheetProgram = "program"

const fetchSheet = async () => {
  const response = await fetch(`https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/${sheetName}?key=${apiKey}`)
  const posts = await response.json();
  
  if(posts != [] || posts != undefined || posts != null){
  const result = [];
  var obj = {};
  var headers = posts.values[0];
  var cols = headers.length;
  var row = [];

  for (let i = 1, l = posts.values.length; i < l; i++){
    row = posts.values[i];
    if(row[1] != undefined){
    obj = {};
    for (let col = 0; col < cols; col++){
      obj[headers[col]] = row[col];
    }
    result.push(obj)
    }
  }
  result.sort((a,b) => {if(a.lastname < b.lastname){return -1}})
  return result;
  }
}

const fetchRandom = async () => {
  const response = await fetch(`https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/${sheetName}?key=${apiKey}`)
  const posts = await response.json();
  
  if(posts != [] || posts != undefined || posts != null){
  const result = [];
  var obj = {};
  var headers = posts.values[0];
  var cols = headers.length;
  var row = [];

  for (let i = 1, l = posts.values.length; i < l; i++){
    row = posts.values[i];
    if(row[1] != undefined){
    obj = {};
    for (let col = 0; col < cols; col++){
      obj[headers[col]] = row[col];
    }
    result.push(obj) 
    }
  }
  console.log(result.length)

  let randomNr = [];
  while(randomNr.length < 6){
    var r = Math.floor(Math.random() * result.length-1) + 1;
    if(randomNr.indexOf(r) === -1){randomNr.push(r)}
  }
  let randomPeople = [];
  randomNr.forEach((nr) => {
    randomPeople.push(result[nr])
  })  
  
  return randomPeople;
  }
}

const fetchTeam = async () => {
  const response = await fetch(`https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/${sheetTeam}?key=${apiKey}`)
  const posts = await response.json();
  
  if(posts != [] || posts != undefined || posts != null){
  const result = [];
  var obj = {};
  var headers = posts.values[0];
  var cols = headers.length;
  var row = [];

  for (let i = 1, l = posts.values.length; i < l; i++){
    row = posts.values[i];
    if(row[1] != undefined){
    obj = {};
    for (let col = 0; col < cols; col++){
      obj[headers[col]] = row[col];
    }
    result.push(obj)
    }
  }
  console.log(result)
  return result;
  }
}

const fetchProgram = async () => {
  const response = await fetch(`https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/${sheetProgram}?key=${apiKey}`)
  const posts = await response.json();
  
  if(posts != [] || posts != undefined || posts != null){
  const result = [];
  var obj = {};
  var headers = posts.values[0];
  var cols = headers.length;
  var row = [];

  for (let i = 1, l = posts.values.length; i < l; i++){
    row = posts.values[i];
    if(row[1] != undefined){
    obj = {};
    for (let col = 0; col < cols; col++){
      obj[headers[col]] = row[col];
    }   
    if(obj.moderators != undefined && obj.moderators != ""){
      let indivmod = obj.moderators.split(".")
      obj.moderators = indivmod
      obj.moderators.forEach((mod,i) => {
        let sepmod = obj.moderators[i].split(",")
        obj.moderators[i] = sepmod
      })
    }

    if(obj.speakers != undefined && obj.speakers != ""){
      let indivspeakers = obj.speakers.split(";")
      obj.speakers = indivspeakers
      obj.speakers.forEach((mod,i) => {
        let sepspeaker = obj.speakers[i].split(",")
        obj.speakers[i] = sepspeaker
      })
      console.log(obj.speakers)
    }
    result.push(obj)
    }
  }
  return result;
  }
}


export {randomInt, menuPos, useMediaQuery, useIsSmall, fetchRandom, fetchSheet, fetchTeam, fetchProgram }