import React, {  useState, useEffect, useRef } from 'react'
import "../styles/speakers.css"
import { motion } from "framer-motion"
import { useIsSmall } from "../util.js"
import { useInView } from "react-intersection-observer"

const Speaker = ({data, color}) => {
  const [person, setPerson] = useState(data)
  const [isVisible, setIsVisible] = useState(false)
  const targetRef = useRef(null);
  const isNotSmall = useIsSmall();
  const fullname = person.fullname.toLowerCase();

  const role = person.role.split(" & ")
  let result;

  // console.log(fullname)

  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     ([entry]) => { 
  //         !isNotSmall && setIsVisible(entry.isIntersecting)
  //         console.log(entry) },
  //     {
  //       root: null,
  //       rootMargin: '0px',
  //       theshold: 1,
  //     }
  //   );
  //   if(targetRef.current){
  //     observer.observe(targetRef.current);
  //   }
  //   return () => {
  //     if(targetRef.current){
  //       observer.unobserve(targetRef.current)
  //     }
  //   }
  // }, [])
  const { ref, inView, entry } = useInView({
    threshold: 1
  })

  if(data.top === undefined || data.bottom === undefined){
    result = {
          ...person,
          top: "5%",
          bottom: "15%"
        }
        setPerson(result)
  }

  const nameVariant = {
    hover: {
      "left": 0
    }
  }
  color = color !== undefined ? color : "var(--yellow)"

  // console.log(fullname)

  return (
    <>
    <motion.div whileHover="hover" whileTap="hover" className="speaker-card" ref={ref} animate={inView && !isNotSmall ? "hover" : null}>
        <a href={person.website} target="_blank" rel="noreferrer">
          <div className="speaker-img img-overlay">
            <img src={`/img/speakers/${fullname}.jpg`} alt="Speaker"/>
            <div className="speaker-role">
              { role.map((r,i) => {
                let classname = `role${role.length}`
                return <h5 style={{backgroundColor: color}} className={classname} key={i}>{r}</h5>
              })}</div>
          </div>
          { person.top &&
          <div className="speaker-info">
            <motion.h3 className="speaker-name bold" variants={nameVariant}
              style={{"left": person.top, backgroundColor: color}}
              >{person.firstname} {person.lastname}</motion.h3>
            <motion.div className="speaker-details" variants={nameVariant}
              style={{"left": person.bottom, backgroundColor: color}}>
              <h5 className="speaker-position">{person.pos}</h5>
              <div className="speaker-aff caption">{person.aff}</div>
            </motion.div>
          </div>
          }
        </a>
    </motion.div>
    </>
  )
}

export default Speaker