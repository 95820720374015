import React from 'react'
import {  Email } from "react-obfuscate-email"

const Footer = () => {
  return (
    <div id="footer">
        <div id="footer-content">
            <p>Contestations.AI</p><p><sub>■</sub></p>
            <p><a href="https://www.aalto.fi/en" target="_blank" rel="noreferrer">Aalto University</a> | <a href="https://www.helsinki.fi/en" target="_blank" rel="noreferrer">University of Helsinki</a></p><p><sub>■</sub></p>
            <p><Email email="info@contestations.ai">info (at) contestations (dot) ai</Email></p><p><sub>■</sub></p>
            <p>2024</p>
        </div>
    </div>
  )
}

export default Footer