import { useRef, useState } from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home"
import About from "./pages/About"
import Program from "./pages/Program"
import Speakers from "./pages/Speakers"
import Showcase from "./pages/Showcase"
import Menu from "./components/Menu"
// import Blastover from "./components/Blastover"
import Header from "./components/Header"
import Footer from "./components/Footer"
import "./style.css"
import "./fonts/epilogue.css"
// import { randomInt } from "./util.js"
// import Showcase from "./pages/Showcase"
// import Watch from "./pages/Watch"

function App() {
  const [isOpen, setOpen] = useState(false)

  const pageElement = useRef(null)
  return (
    <>
    
    {/* <Blastover /> */}
      <Router basename={process.env.PUBLIC_URL}>

      <Header isOpen={isOpen} closeMenu={() => setOpen(false)}/>
      <Menu pageElementRef={pageElement} isOpen={isOpen} onOpen={() => setOpen(true)} onClose={() => setOpen(false)}/>
      <div id="page-container" ref={pageElement}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/program" element={<Program />} />
          <Route path="/people" element={<Speakers />} />
          <Route path="/showcase" element={<Showcase />} />
        </Routes>
        <Footer />
        </div> 
      </Router>
    </>
  );
}

export default App;
