import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import { motion } from "framer-motion"
import { Divide as Hamburger } from "hamburger-react"
import { menuPos } from "../util.js"
import "../styles/menu.css"

const Menu = ({pageElementRef, isOpen, onOpen, onClose}) => {
    const navigate = useNavigate();
    // const [isOpen, setOpen] = useState(false);
    const [boxes, setBoxes] = useState([menuPos(), menuPos(), menuPos(), menuPos(), menuPos(), menuPos(), menuPos(), menuPos()])

    useEffect(() => {
      if(isOpen){
        setBoxes([menuPos(), menuPos(), menuPos(), menuPos(), menuPos(), menuPos(), menuPos(), menuPos()])
        pageElementRef.current.className = "blur"
      } 
    }, [isOpen])
        
    const container = {
      hidden: { opacity: 0,  transition: { when: 'afterChildren', duration: 0.2, staggerChildren: 0.05, staggerDirection: -1, } },
      show: {  opacity: 1,  transition: { when: 'beforeChildren', duration: 0.1, staggerChildren: 0.05, }},
    }
    const item = {
      hidden: {opacity: 0,  transition: { ease: "backIn", }},
      show: { opacity: 1,  transition: { ease: "backIn", }}
    }
    
  return (
    <>
    <motion.div layout id="menu-on" 
      onClick={() => {isOpen && onClose() }}
      className={isOpen? "" : "nopointer"}
      initial="hidden"
      animate={isOpen? "show" : "hidden"}
      onAnimationComplete={() => {!isOpen? pageElementRef.current.className = "" : pageElementRef.current.className = "blur"}}
      variants={container}>
        {
        boxes.map((box,i) => {
          return <motion.div className="menu-box" variants={item} key={i}
            style={{width: box[2], height: box[2],
                  top: box[1],
                  left: box[0]
            }}></motion.div>
        })
        }
        <motion.div variants={item} className="menu-item" onClick={() => {navigate("/about"); onClose()}}><span>About</span></motion.div>
        <motion.div variants={item} className="menu-item" onClick={() => {navigate("/program"); onClose()}}><span>Program</span></motion.div>
        <motion.div variants={item} className="menu-item" onClick={() => {navigate("/people"); onClose()}}><span>People</span></motion.div>
        <motion.div variants={item} className="menu-item" onClick={() => {navigate("/showcase"); onClose()}}><span>Showcase</span></motion.div>
        {/* <motion.div variants={item} className="inactive-menu menu-item"><span>Watch</span></motion.div>  */}
    </motion.div>
    
    <div id="menu-off">
      <a href="https://link.webropolsurveys.com/Participation/Public/a5863f82-0877-40d0-ab47-b19491ec457b?displayId=Fin3176303" target="_blank" rel="noreferrer">
        <h4 id="tickets" className="frame-box button">register</h4>
      </a>
      <div id="hamburger" className="frame-box"><Hamburger toggled={isOpen} toggle={isOpen? onClose : onOpen} color="var(--yellow)" label="Menu icon" size={30}/></div>
    </div>
    
    </>
  )
}

export default Menu