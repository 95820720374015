import React, { useState, useEffect } from 'react'
import { Link, scrollSpy } from "react-scroll"
import {  Email } from "react-obfuscate-email"
import { motion } from "framer-motion"
import { useIsSmall, fetchTeam, randomInt } from "../util.js"
import { Divide as Hamburger } from "hamburger-react"
import "../styles/about.css"
import Speaker from "../components/Speaker"

const About = () => {
  const isSmall = useIsSmall();
  const [isOpen, setOpen] = useState(false)
  const [data, setData] = useState(null)

  useEffect(() => {
    document.title = "Contestations.AI | About"
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    scrollSpy.update();
    // fetchSheet().then(result => {
    //   setData(result)})
    fetchTeam().then(result => {
      setData(result)
    })
  },[])


  const web = !isSmall
  ? {
    closed: {
      x: -200,
      transition: {duration: 0.5}
    },
    // exit: {
    //   x: -160,
    //   transition: {duration: 0.2}
    // },
    open: {
      x: 0,
      transition: {duration: 0.5}
    }
  }
  : {
    animate: {
      x: 0,
      transition: {duration: 0.2}
    },
    // exit: {
    //   x: -100,
    //   transition: {duration: 0.5}
    // },
  }
  let top, bottom;

  return (
      <div id="about-page">
        <div id="about-menu-container">
          <motion.div variants={web} initial="exit" animate={isSmall? "animate" : isOpen? "open" : "closed"} exit="exit" id="about-menu-div">
            <div id="about-menu" className="frame-lt" style={{"--w": "50px", "--b": "8px"}}>
              <Link to="about-container" offset={-30} smooth duration={200} activeClass="active" spy={true}>About</Link>
              <Link to="tickets-container" offset={-30} smooth  duration={200} activeClass="active" spy={true}>Attending</Link>
              <Link to="location-container" offset={-30} smooth duration={200} activeClass="active" spy={true}>Location</Link>
              <Link to="organizers-container" offset={-30} smooth duration={200} activeClass="active" spy={true}>Team</Link>
              <Link to="partners-container" offset={-30} smooth duration={200} activeClass="active" spy={true}>Partners</Link>
              <Link to="contact-container" offset={-30} smooth  duration={200} activeClass="active" spy={true}>Contact</Link>
            </div>
            <motion.div id="about-hamburger"><Hamburger toggled={isOpen} toggle={setOpen} size={25}/></motion.div>
          </motion.div>
        </div>

        <div>
          <div id="about-container" onClick={() => {setOpen(false)}}>
            <div id="about-text-container" data-section>
              <div id="about-text-title">
                <div>Transdisciplinary symposium on AI, Human Rights and Warfare</div>
                <h2>Contestations.AI</h2>
              </div>
              <div id="about-text-text">
                <p>
                  Artificial Intelligence (AI), Big Data analytics and Automated Decision Making (ADM) are increasingly being used for surveillance, targeting, and autonomous drone warfare, in addition to proliferating misinformation on social media during war and conflicts. Conversely, these technologies are also leveraged for investigation of human rights violations as done by members of Forensic Architecture and Bellingcat. How should researchers, scholars, government actors and civil society engage and act critically to highlight, investigate, and prevent the use of AI-based systems in perpetuating human rights violations and warfare and devise critical policies and practices that mitigate harms to society today? 
                </p>
                <p>
                  This symposium brings critical perspectives from AI researchers, social scientists, investigative journalists, and scholars in International Humanitarian Law (IHL), human rights, Members of Parliament, and NGOs dealing with these concerns. We will examine these aspects in the context of ongoing conflicts in Gaza and Ukraine, among others globally, from the role of AI for spreading misinformation in war, to autonomous warfare, and investigative journalism to uncover human rights violations. The symposium will also insert the question of technology into its larger social and political context, particularly examining aspects such as the geopolitics of technology, securitisation of the nation state-system, and the role of corporate interests in technologized warfare. Our aim is to encourage inter-disciplinary and critical theorizing on such topics, while developing an action agenda for future research and pragmatic societal outcomes.
                </p>
                <p>
                  In addition to talks and panels, the event will showcase experimental digital media works by artists critically engaging contestations in AI technologies and society, curated by Matti Niinimäki, Department of Art and Media, Aalto University.
                </p>
                <p>
                  The symposium is held in conjunction with these related events:
                </p>
                <div className="relatedevent frame-lt">
                  <p>
                    <b className="bold"><a href="https://www.hiig.de/en/events/ai-and-warfare/" target="_blank" rel="noreferrer" className="link">AI and Warfare Conference</a></b><br/>
                    Oct 16-18, 2024, Humboldt Institute for Internet and Society, Berlin
                  </p>
                </div>
                <div className="relatedevent frame-lt">
                    <p>
                      <b className="bold"><a href="https://fcai.fi/ai-day-2024" target="_blank"rel="noreferrer" className="link">AI Day 2024 + Nordic AI Meet</a></b><br/>
                      October 21-22, 2024, University of Helsinki
                    </p>
                </div>
                <div className="relatedevent frame-lt">
                    <p>
                      <b className="bold"><a href="https://www.helsinki.fi/en/conferences/epistemic-challenges-and-democratic-accountability-european-media" target="_blank" rel="noreferrer" className="link">Epistemic Challenges & Democratic Accountability in European Media Conference</a></b><br/>
                      October 24-25, 2024, University of Helsinki 
                    </p>
                </div>
                <p>
                  <br/>The symposium takes place just before the <a href="https://www.un.org/en/observances/un-day" target="_blank" rel="noreferrer" className="link">United Nations Day</a> on October 24th.
                </p>
              </div>
              <h1 id="about-text-about">
                About
              </h1>
            </div>
          </div>

          <div id="tickets-container" data-section>
            <h1>Attending the symposium</h1>
            <div><p>The symposium will be held on location at the Train Factory and as an online livestream. It is open to the public and free of charge to attend, but please register in advance as space is limited at the event venue.
            Tickets at the door will be sold for a small fee.</p></div>
            <div id="tickets-grid">
              <div></div>
              <div className="tickets-audience middle">Students</div>
              <div className="tickets-audience right">General</div>
              <div><h2 style={{"border":"none"}} className="tickets-registration">Online registration <br/>& livestream</h2><p>by October 22, 2024</p></div>
              <h2 className="middle">free :)</h2>
              <h2 className="right">free :)</h2>
              <div><h2 style={{"border":"none"}} className="tickets-registration">Tickets at the door</h2><p>on October 23, 2024</p></div>
              <h2 className="middle">€25</h2>
              <h2 className="right">€55</h2>
            </div>
          </div>
          
          <div id="location-container">
          
            <h1>Location</h1>
            <div id="location-img-container">
              <div id="img-outside" className="img-overlay"><img src="img/other/konepaja_outside.jpeg" alt="Konepaja building"/></div>
              <div id="img-inside" className="img-overlay"><img src="img/other/konepaja_theatre.jpeg" alt="Konepaja theatre"/></div>
              <div id="location-map"><iframe title="Google Maps location of Konepaja theatre" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1983.2734807389566!2d24.94390991263038!3d60.19275687493981!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x469209d665363037%3A0x5c90ae376f657ffd!2sVallila%20Kansallisteatteri!5e0!3m2!1sen!2sfi!4v1725550097518!5m2!1sen!2sfi"  style={{"border":"0", "margin": "0"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                <p><a href="https://trainfactory.com/whatshere/kansalisteatteri/" target="_blank" rel="noreferrer" className="link">Vallila Kansallisteatteri</a><br/>
                    Konepaja (Train Factory)<br/>
                    Aleksis Kiven katu 17,<br/>
                    00510 Helsinki</p>
              </div>
              
            </div>
          </div>

          <div id="organizers-container">
            <h1>Team</h1>
            <div id="organizers-cards">
              {data !== null && data.map((person, i) => {
                  const fullfirstname = person.firstname.replace(/\s/g, "")
                  const fulllastname = person.lastname.replace(/\s/g, "")
                  const fullname = fullfirstname + fulllastname
                    if(isSmall){
                      let length = 60-(fullname.length*2);
                      top = randomInt(0,length) + "%"
                      bottom = randomInt(0,25) + "%"
                    } else{ 
                      let length = 90-(fullname.length*3);
                      top = randomInt(0,length) + "%"
                      bottom = randomInt(0,30) + "%"
                    }
                return <Speaker data={{...person, top: top, bottom: bottom}} key={i}/>
                // console.log(person)
              })}
            </div>
          </div>

          <div id="partners-container" data-section>
            <h1>Partners</h1>
            <div id="partner-logos">
              <div><a href="https://fcai.fi" target="_blank" rel="noreferrer"><img src="/img/other/FCAI.svg" alt="FCAI logo"/></a></div>
              <div style={{"background": "white", "padding": "5px 10px", "width": "fit-content"}}><a href="https://www.fime.fi/en/" target="_blank" rel="noreferrer"><img src="/img/other/fime.png" alt="Finnish Institute in the Middle East logo"/></a></div>
              <div><a href="https://www.aalto.fi/en/aalto-media-lab" target="_blank" rel="noreferrer"><img src="/img/other/medialab.svg" alt="Aalto Media Lab logo"/></a></div>
            </div>
          </div>

          <div id="contact-container" data-section>
            <h1>Contact</h1>
            <div id="contacts">
              <Email email="info@contestations.ai">INFO (at) CONTESTATIONS (dot) AI</Email>
              <div><div>INSTAGRAM</div><div>FACEBOOK</div><div>LINKEDIN</div></div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
  )
}

export default About