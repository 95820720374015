import React, {useState, useEffect} from 'react'
import Speaker from "../components/Speaker"
import {fetchSheet, useIsSmall, randomInt} from "../util.js"
// import data from "../data/data.json"

const Speakers = () => {
  const [data, setData] = useState(null)
  const isNotSmall = useIsSmall();
  const [spinner, setSpinner] = useState(true)

  useEffect(() => {
    document.title = "Contestations.AI | People";
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    fetchSheet().then(result => {
        setData(result)
        setSpinner(false)})
  },[])

 



  // if(data!=null){
  //   data.forEach((p, i) => {
  //     // const [person, setPerson] = useState(p)
  //     let top, bottom;
  //     if(isNotSmall){
  //       // length = (40 - fullname.length*2)
  //       top = randomInt(0,30) + "%"
  //       bottom = randomInt(0,25) + "%"
  //       // console.log(fullname, length, top)   
  //     } else{ 
  //       // length = (80-fullname.length*2)
  //       top = randomInt(0,60) + "%"
  //       bottom = randomInt(0,30) + "%"
  //     }
  //     let result = {
  //       ...p,
  //       top: top,
  //       bottom: bottom
  //     }
  //     p = result
  //     // setPerson(result)
  //   })
  //   console.log(data)
  // }

  return <div id="speaker-page">
  <div>
    <h1>People</h1>
  </div>
  { spinner && 
  <p style={{"color": "yellow"}}>Loading people...</p>
  }
  <div id="speaker-container">
      {data !== null && data.map((speaker, i) => {
         let top, bottom;
        const fullfirstname = speaker.firstname.replace(/\s/g, "")
        const fulllastname = speaker.lastname.replace(/\s/g, "")
        const fullname = fullfirstname + fulllastname
          if(isNotSmall){
            let length = 60-(fullname.length*2);
            top = randomInt(0,length) + "%"
            bottom = randomInt(0,25) + "%"
          } else{ 
            let length = 70-(fullname.length*2);
            top = randomInt(0,length) + "%"
            bottom = randomInt(0,40) + "%"
          }
      return (
        <Speaker data={{...speaker, top: top, bottom: bottom}} key={i}/>
      )})}
  </div>
  <p className="littlenote"><i>* more people to be announced</i></p>
  </div>
}

export default Speakers