import React, {useState, useEffect} from 'react'
import {motion} from "framer-motion"
import "../styles/program.css"
import { fetchProgram, fetchSheet } from "../util.js"

const Program = () => {
  const [isOpen, setIsOpen] = useState({})
  const [data, setData] = useState(null)
  const [people, setPeople] = useState(null)
  const [spinner, setSpinner] = useState(true);

  useEffect(() => {
    document.title = "Contestations.AI | Program"
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    fetchProgram().then(result => {
      setData(result)
      
      // const href = window.location.href.substring(
      //   window.location.href.lastIndexOf('#') + 1
      // );
      // if(href != ""){
      //   toggleOpen(href)
      // }
      // let element;
      // let retries = 0;
      //     const scroll = () => {
      //       retries += 0;
      //       if (retries > 50) return;
      //         element = document.getElementById(href)
      //       if (element) {
      //         setTimeout(() => element.scrollIntoView({ behavior: "smooth"}), 0);
      //       } else {
      //         setTimeout(scroll, 100);
      //       }
      //     };
      //     scroll();
        setSpinner(false)
    })
    fetchSheet().then(result => {
      setPeople(result)
    })
  },[])

  console.log(people)

  const toggleOpen = (category) => {
    setIsOpen({
      ...isOpen,
      [category]: !isOpen[category]
    })
  }

  const toggleVariants = {
    open: {
      height: "max-content",
      transition: {duration: 0.25, type: "tween", ease: [0.34, 0.02, 0.73, 0.98]}
    },
    closed: {
      height: "0px",
      transition: {duration: 0.25, type: "tween", ease: [0.34, 0.02, 0.73, 0.98]}
    }
  }


  return (
    <div id="program-page">
      <div id="program-container">
        <div>
          <h1>Program</h1>
        </div>
        { spinner && 
          <p style={{"color": "yellow"}}>Loading program...</p>
        }
      <div>
      { data !== null && data.map((item) => {
        return <div className={`schedule-container ${item.colortag}`} id={item.id}>
          <h2 className="hsb">{item.time}</h2>
            { item.topic ? <>
              <h2 className="hsr">{item.title}</h2>
              <h4>Topic</h4>
              <div className="program-topic" onClick={() => {toggleOpen(item.id)}}>
                <h3>{item.topic}</h3>
                <div className="toggle-icon">{isOpen[item.id] ? "-" : "+"}</div>
              </div>
              <motion.div className="program-toggle" variants={toggleVariants} initial="closed" animate={isOpen[item.id] ? "open" : "closed"} >
                  <div>
                    <h4>Moderator(s)</h4>
                    {item.moderators.map((mod) => {
                      return <p>{mod[0]} <sup>{mod[1]}</sup></p>
                    })} 
                  </div>
                  <div>
                    <h4>Panelists</h4>
                    { item.speakers.map((pan) => { 
                      return <p>{pan[0]} <sup>{pan[1]}</sup></p>
                    })}
                  </div>
                </motion.div>
            </>
            : item.speakers ?
            <>
              <div className="program-topic" onClick={() => {toggleOpen(item.id)}}>
                <h2 className="hsr">{item.title}</h2>
                <div className="toggle-icon" >{isOpen[item.id] ? "-" : "+"}</div>
              </div>
              <motion.div className="program-toggle" variants={toggleVariants} initial="closed" animate={isOpen[item.id] ? "open" : "closed"}>
                  <div>
                    {/* <h4>Moderator(s)</h4>
                    {item.moderators.map((mod) => {
                      return <p>{mod[0]} <sup>{mod[1]}</sup></p>
                    })}  */}
                  </div>
                  <div>
                    <h4>People</h4>
                    { item.speakers.map((pan) => { 
                      return <p>{pan[0]} <sup>{pan[1]}</sup></p>
                    })}
                  </div>
                </motion.div>
            </>
            : <h2 className="hsr">{item.title}</h2>
            }
          </div>
      })}

      </div>
      <p className="littlenote"><i>* program is tentative and subject to change</i></p>
    </div>
    </div>
  )
}

export default Program