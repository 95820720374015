import React, {useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
// import {motion} from "framer-motion"
import "../styles/landing.css"
import { useIsSmall, fetchRandom, randomInt } from "../util.js"
import Speaker from "../components/Speaker"
import Arrowbutton from "../components/Arrowbutton"

const Home = () => {
  const navigate = useNavigate();
  const isNotSmall = useIsSmall();
  // const [hover, setHover] = useState();
  const [people, setPeople] = useState(null);
  // const [toggleInfo, setToggleInfo] = useState(false);

  useEffect(() => {
    fetchRandom().then(randomPeople => {setPeople(randomPeople)})
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  },[])
  // console.log(people)

  // if(isNotSmall){
  //   console.log("isNotSmall")
  // } else{
  //   console.log("!isNotSmall")
  // }

  // const containerVariants = {
  //   init: {
  //     "--w": "6vw", 
  //     // "--c": "var(--darkblue)",
  //     "--c": "var(--yellow)",
  //     // "--c": "red",
  //     transition: {duration: 0}
  //   },
  //   killyourself: {
  //     "--w": "6vw",
  //     "--c": "var(--yellow)",
  //     transition: {staggerChildren: 0.02}
  //   },
  //   exit: {
  //     "--w": "6vw", 
  //     // "--c": "var(--darkblue)",
  //     "--c": "var(--yellow)",
  //     // "--c": "red",
  //     transition: {staggerChildren: 0.02, staggerDirection: -1}
  //   }
  // }
  // const rectVariants = {
  //   init: {
  //     scale: 1,
  //     transition: {duration: 0}
  //   },
  //   killyourself: {
  //     scale: 0
  //   },
  //   exit: {
  //     scale: 1,
  //     transition: {ease: "easeOut"}
  //   },
  //   loop: {
  //     scale: 0,
  //     // transition: {
  //     //   repeat: Infinity
  //     // }
  //   }
  // }

  return (
    <div id="landing-page">
    {/* <div id="img-info">
        { toggleInfo ?
        <div id="img-info-popup">
        <div>A Palestinian child with a kite in front of the destroyed Al-Nada towers in Beit Hanoun, North Gaza, August 4, 2014. The poignant photo taken by Anne Paq, an award-winning human rights photographer and filmmaker with the Activestills Collective. It was taken for the film Return to Seifa Village, a sequel to the documentary film Flying Paper, co-directed by Nitin Sawhney and Roger Hill.</div>
        <div><br/>The Israeli siege of the Gaza Strip in 2014 was conducted as part of “Operation Protective Edge", lasting for fifty days between July 8 and August 26. The assault claimed the lives of 2,220 Palestinians, including at least 1,492 civilians, according to the United Nations Office for the Coordination of Humanitarian Affairs (OCHA).</div>
        </div>
        :
        <div></div>
        }
        <br/>
        <div id="img-info-icon"  className="click" onClick={()=> setToggleInfo(!toggleInfo)}>{toggleInfo? "x" : "i"}</div>
      </div> */}
    <div id="hero-container-container">
      {/* <motion.div className="frame-box" style={{"--b": "8px"}} initial="init" animate={hover == undefined ? null : hover ? "killyourself" : !isNotSmall ? "loop" : "exit"} variants={containerVariants}  id="hero-container">
          <div>
            <div id="rectangles">
              <motion.div id="rectA" className="rectangles"
                style={{"height": "30%", "width": "13%", "left": "26%", "top": "28%"}} variants={rectVariants}>
              A</motion.div>
              <motion.div id="rectB" className="rectangles"
                style={{"height": "20%", "width": "8%", "left": "87.1%", "top": "8%"}} variants={rectVariants}>
              B</motion.div>
              <motion.div id="rectC" className="rectangles"
                style={{"height": "15%", "width": "25%", "left": "49%", "top": "41%"}} variants={rectVariants}>
              C</motion.div>
              <motion.div id="rectD" className="rectangles"
                style={{"height": "27%", "width": "9.9%", "left": "47.3%", "top": "60%"}} variants={rectVariants}>
              D</motion.div>
              <motion.div id="rectE" className="rectangles"
                style={{"height": "20%", "width": "20%", "left": "0", "top": "80%"}} variants={rectVariants}>
              E</motion.div>
              <motion.div id="rectF" className="rectangles"
                style={{"height": "15%", "width": "25%", "left": "50%"}} variants={rectVariants}>
              F</motion.div>
              <motion.div id="rectG" className="rectangles"
                style={{"height": "17%", "width": "10%", "left": "0"}} variants={rectVariants}>
              G</motion.div>
              <motion.div id="rectH" className="rectangles"
                style={{"height": "30%", "width": "35%", "left": "65.3%", "top": "52%"}} variants={rectVariants}>
              H</motion.div>
              <motion.div id="rectI" className="rectangles"
                style={{"height": "33%", "width": "22%", "left": "53%", "top": "68.5%"}} variants={rectVariants}>
              I</motion.div>
              <motion.div id="rectJ" className="rectangles"
                style={{"height": "25%", "width": "13%", "left": "26%", "top": "55%"}} variants={rectVariants}>
              J</motion.div>
              <motion.div id="rectK" className="rectangles"
                style={{"height": "47%", "width": "13%", "left": "0", "top": "35%"}} variants={rectVariants}>
              K</motion.div>
              <motion.div id="rectL" className="rectangles"
                style={{"height": "11%", "width": "28%", "left": "72%"}} variants={rectVariants}>
              L</motion.div>
              <motion.div id="rectM" className="rectangles"
                style={{"height": "31%", "width": "13%", "left": "38%", "top": "25%"}} variants={rectVariants}>
              M</motion.div>
              <motion.div id="rectN" className="rectangles"
                style={{"height": "27.8%", "width": "28%", "left": "0", "top": "12.5%"}} variants={rectVariants}>
              N</motion.div>
              <motion.div id="rectO" className="rectangles"
                style={{"height": "20%", "width": "8%", "left": "92%", "top": "6%"}} variants={rectVariants}>
              O</motion.div>
              <motion.div id="rectP" className="rectangles"
                style={{"height": "20%", "width": "30%", "left": "70%", "top": "80%"}} variants={rectVariants}>
              P</motion.div>
              <motion.div id="rectQ" className="rectangles"
                style={{"height": "35%", "width": "30.8%", "left": "48%", "top": "7%"}} variants={rectVariants}>
              Q</motion.div>
              <motion.div id="rectR" className="rectangles"
                style={{"height": "25%", "width": "25%", "left": "30%", "top": "76.4%"}} variants={rectVariants}>
              R</motion.div>
              <motion.div id="rectS" className="rectangles"
                style={{"height": "38%", "width": "9%", "left": "21.2%", "top": "38%"}} variants={rectVariants}>
              S</motion.div>
              <motion.div id="rectT" className="rectangles"
                style={{"height": "30%", "width": "35%", "left": "18.2%"}} variants={rectVariants}>
              T</motion.div>
              <motion.div id="rectU" className="rectangles"
                style={{"height": "30%", "width": "27%", "left": "73%", "top": "23.5%"}} variants={rectVariants}>
              U</motion.div>
              <motion.div id="rectV" className="rectangles"
                style={{"height": "9.1%", "width": "19%", "left": "38%", "top": "55%"}} variants={rectVariants}>
              V</motion.div>
              <motion.div id="rectW" className="rectangles"
                style={{"height": "30%", "width": "22%", "left": "10%", "top": "70%"}} variants={rectVariants}>
              W</motion.div>
              <motion.div id="rectX" className="rectangles"
                style={{"height": "20%", "width": "10%", "left": "12%", "top": "52.5%"}} variants={rectVariants}>
              X</motion.div>
            </div>
            <img src="/img/other/boy.jpg"/>
            
            <div id="frames">
                {/* <motion.div id="frameCursor"></motion.div> 
                <motion.div id="frameA" onMouseOver={() => {setHover(true)}} onMouseOut={() => {setHover(false)}}></motion.div>
                <motion.div id="frameB" onMouseOver={() => {setHover(true)}} onMouseOut={() => {setHover(false)}}></motion.div>
                <motion.div id="frameC" onMouseOver={() => {setHover(true)}} onMouseOut={() => {setHover(false)}}></motion.div>
                <motion.div id="frameD" onMouseOver={() => {setHover(true)}} onMouseOut={() => {setHover(false)}}></motion.div>
                <motion.div id="frameE" onMouseOver={() => {setHover(true)}} onMouseOut={() => {setHover(false)}}></motion.div>
            </div>
          </div>
      </motion.div> */}
      <div id="still-hero" style={{backgroundImage: "url(/img/other/header.jpg)"}}>
        <img src="img/other/hero-img.jpg" alt="Contestations.ai"/>
      </div>

      <div id="landing-title">
        {/* <h1>Contestations.AI</h1>
        <p style={{"color": "var(--yellow)"}}>A Transdisciplinary Symposium on AI, Human Rights and Warfare</p> */}
        <div>
          <p className="click" onClick={() => {navigate("/about")}}>ABOUT</p>
          <p className="click" onClick={() => {navigate("/program")}}>PROGRAM</p>
          <p className="click" onClick={() => {navigate("/people")}}>PEOPLE</p>
          <p className="click" onClick={() => {navigate("/showcase")}}>SHOWCASE</p>
        </div>
      </div>

      <div id="landing-about">
        <div id="landing-about-container">
          <div className="frame-lt" style={{"--w": "40px", "--b": "8px"}}><p>Artificial Intelligence (AI), Big Data analytics and Automated Decision Making (ADM) are increasingly being used for surveillance, targeting, and autonomous drone warfare, in addition to proliferating misinformation on social media during war and conflicts.</p></div>
          <div className="frame-br" style={{"--w": "40px", "--b": "8px"}}><p>This symposium brings critical perspectives from AI researchers, social scientists, investigative journalists, and scholars in International Humanitarian Law (IHL), human rights, Members of Parliament, and NGOs dealing with these concerns.</p></div>
            <Arrowbutton text="Read More" color="var(--darkblue)" link="/about" />
        </div>
      </div>

      <div id="landing-speakers">
        <h1>People</h1>
        <div>
          <div id="landing-speakers-container">
            {people !== null && people.map((speaker, i) => {
               let top, bottom;
              const fullfirstname = speaker.firstname.replace(/\s/g, "")
              const fulllastname = speaker.lastname.replace(/\s/g, "")
              const fullname = fullfirstname + fulllastname
                if(isNotSmall){
                  let length = 60-(fullname.length*2);
                  top = randomInt(0,length) + "%"
                  bottom = randomInt(0,25) + "%"
                } else{
                  let length = 90-(fullname.length*3);
                  top = randomInt(0,length) + "%"
                  bottom = randomInt(0,30) + "%"
                }
            return (
              <Speaker data={{...speaker, top: top, bottom: bottom}} key={i}/>
            )})}
          </div>
          <div id="allspeakersfooter">
            <p><i>* this is a randomly generated selection of participants</i></p>
            <Arrowbutton text="All People" color="var(--yellow)" link="/people" />
          </div>
          
        </div>
      </div>

      <div id="landing-info">
          <div id="speakers-info">
            { isNotSmall ?
            <img src="img/other/landingsvg/speakers-desktop.svg" alt="25+ speakers; academics, authors, journalists, filmmakers, researchers, and more."/>
            :
            <img src="img/other/landingsvg/speakers-mobile.svg" alt="25+ speakers; academics, authors, journalists, filmmakers, researchers, and more."/>
            }
          </div>
          <div id="art-info">
          { isNotSmall ?
            <img src="img/other/landingsvg/art-desktop.svg" alt="Performances and Media Art"/>
            :
            <img src="img/other/landingsvg/art-mobile.svg" alt="Performances and Media Art"/>
          }
          </div>
          <div id="venue-info">
          { isNotSmall ?
            <div>
            <img src="img/other/landingsvg/venue-desktop.svg" id="venue-text" alt="23.10.2024 at the Train Factory in Helsinki"/>
            <img src="img/other/landingsvg/train_factory.jpg" id="venue-desktop" alt="The Train Factory"/>
            </div>
            :
            <>
            <img src="img/other/landingsvg/venue-mobile.svg" id="venue-text" alt="23.10.2024 at the Train Factory in Helsinki"/>
            <img src="img/other/landingsvg/train_factory.jpg" id="venue-mobile" alt="The Train Factory"/>
            </>
          }
          </div>
      </div>
      <div id="landing-logos">
      <a href="https://www.aalto.fi/en" target="_blank" rel="noreferrer"><img src="img/other/aalto-logo.svg" id="aalto-logo" alt="Aalto University Logo"/></a>
      <a href="https://www.helsinki.fi/en" target="_blank" rel="noreferrer"><img src="img/other/helsinki-logo.svg" id="helsinki-logo" alt="University of Helsinki Logo"/></a>
      </div>
    </div>
    </div>
  )
}

export default Home