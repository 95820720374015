import React from 'react'
import "../styles/menu.css"
import { useNavigate } from "react-router-dom"

const Header = ({closeMenu}) => {
  const navigate = useNavigate();

  return (
    <div id="header">
        <div className="click" onClick={() => {navigate("/"); closeMenu()}}>Contestations.AI</div>
        <div>October 23, 2024<br/><a href="https://trainfactory.com" target="_blank" rel="noreferrer">The Train Factory</a>, Helsinki<br/>& Livestreamed</div>
    </div>
  )
}

export default Header